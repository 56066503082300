<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4">
        <div class="pa-6">
          <v-row>
            <v-col cols="12">
              <h1 class="font-weight-bold">Send Invoice email</h1> 
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="invoiceNumberSelected"
                :items="invoiceNumbersList"
                item-text="invoiceNumbers"
                item-value="invoiceNumbers"
                dense
                outlined
                menu-props="auto"
                label="Select an Invoice Number"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedCompanies"
                :items="companies"
                item-value="id"
                item-text="name"
                label="Select Companies (optional)"
                multiple
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="ignoreErrors"
                label="Ignore Errors"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn
                outlined
                primary
                v-if="invoiceNumberSelected"
                :loading="loading"
                @click="sendXeroBillingEmail"
              >
                Send Xero Billing Email
                {{
                  selectedCompanies.length
                    ? `For ${selectedCompanies.length} ${
                        selectedCompanies.length === 1 ? "Company" : "Companies"
                      }`
                    : "All Companies"
                }}
                - Invoice {{ invoiceNumberSelected }}
              </v-btn>
            </v-col>
          </v-row>
          <div v-if="errors.length" class="pt-2">
            <h2 class="font-weight-bold red--text">Errors</h2>
            <p v-for="(error, i) in errors" :key="i">- {{ error }}</p>
          </div>
        </div>
      </v-card>
      <v-card :loading="loading" class="mb-4 pa-6" >
        <v-row>
          <v-col cols="12">
            <h1 class="font-weight-bold">Emails By Company</h1>
          </v-col>
          <v-col cols="4" v-for="company in companies" :key="company.id">
            <v-card outlined class="pa-2" color="indigo lighten-5">
              <v-card-title
                class="px-2 py-0 deep-purple--text font-weight-bold"
              >
                {{ company.code }} - {{ company.name }}
              </v-card-title>

              <!-- list of emails -->
              <v-list
                class="py-1 px-0"
                rounded
                v-if="invoiceEmails[company.id]"
                color="transparent"
              >
                <v-divider class="mx-2 my-2" />

                  <v-list-item
                    v-for="(email, i) in invoiceEmails[company.id]"
                    :key="i"
                  >
                    <v-list-item-title class="wrap-text">{{ email.email }}</v-list-item-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="warning"
                      icon
                      @click="deleteInvoiceEmail(email)"
                      :loading="loading"
                    >
                      X
                    </v-btn>
                  </v-list-item>
              </v-list>

              <!-- add new email section -->
              <v-divider class="mx-2 my-2" />
              <div class="pa-2">
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model.trim="emailFields[company.id]"
                      label="email"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="primary"
                      outlined
                      @click="insertInvoiceEmail(company.id)"
                      :disabled="!emailFields[company.id]"
                      :loading="loading"
                      >Add</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import BillingSnap from "@/services/BillingSnap.js";
import CommitedData from "@/services/CommitedData.js";

export default {
  data() {
    return {
      validForm: true,
      // Title
      title: "Xero Invoice Emails",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      invoiceEmails: [],
      companies: [],
      selectedCompanies: [],

      emailFields: {},
      invoiceNumbersList: [],
      invoiceNumberSelected: "",
      ignoreErrors: false,
      errors: [],
    };
  },

  created() {
    this.getCompanies();
    this.getInvoiceEmails();
    this.getInvoiceNumbers();
  },
  methods: {
    async getInvoiceNumbers() {
      this.loading = true;
      CommitedData.getInvoiceNumbers()
        .then((invoiceNumbers) => {
          this.invoiceNumbersList = invoiceNumbers;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async getCompanies() {
      let companyArray = await this.$root.sharedData.getCompanies();
      if (companyArray) {
        this.companies = companyArray;
      }
    },
    groupInvoiceEmailsByCompanyId(invoiceEmails) {
      let grouped = {};
      if (invoiceEmails && invoiceEmails.length) {
        for (let email of invoiceEmails) {
          if (!grouped[email.company_id]) {
            grouped[email.company_id] = [];
          }
          grouped[email.company_id].push(email);
        }
      }
      return grouped;
    },
    getInvoiceEmails() {
      this.loading = true;
      this.invoiceEmails = [];
      BillingSnap.getInvoiceEmails()
        .then((response) => {
          this.loading = false;
          this.invoiceEmails = this.groupInvoiceEmailsByCompanyId(response);
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    deleteInvoiceEmail(item) {
      this.loading = true;
      let reqBody = { id: item.id };
      BillingSnap.deleteInvoiceEmail(reqBody)
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.text = `email deleted`;

          this.getInvoiceEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    insertInvoiceEmail(companyId) {
      let email = this.emailFields[companyId];
      if (!email) return;
      let reqBody = {
        email: email,
        company_id: companyId,
      };

      this.loading = true;
      BillingSnap.insertInvoiceEmail(reqBody)
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          this.text = `email added`;
          this.emailFields[companyId] = "";
          this.getInvoiceEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    sendXeroBillingEmail() {
      this.errors = [];
      let invoiceNumber = this.invoiceNumberSelected;
      if (!invoiceNumber) return;
      let reqBody = {
        invoiceNumber: invoiceNumber,
      };
      if (this.ignoreErrors) {
        reqBody.skipErrors = true;
      }
      if(this.selectedCompanies && this.selectedCompanies.length){
        reqBody.companyIds = this.selectedCompanies
      }

      this.loading = true;
      BillingSnap.sendXeroBillingEmail(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = response;
         
          this.getInvoiceEmails();
        })
        .catch((error) => {
          this.snackbar = true;
          this.errors = error.response.data.message.split(",");
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
  },
};
</script>
<style>
.v-card .v-card__title {
  font-size: 1rem;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>
